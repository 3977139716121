/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * adlery-pro:
 *   - http://typekit.com/eulas/00000000000000007737074e
 * basic-sans:
 *   - http://typekit.com/eulas/00000000000000007735abe8
 *   - http://typekit.com/eulas/00000000000000007735abfc
 * canvas-script:
 *   - http://typekit.com/eulas/0000000000000000774ba093
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2023-04-24 00:12:39 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=ekz5lwe&ht=tk&f=35474.35480.51315.52049&a=38273869&app=typekit&e=css");

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/515e84/00000000000000007735abfc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/515e84/00000000000000007735abfc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/515e84/00000000000000007735abfc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"adlery-pro";
src:url("https://use.typekit.net/af/f862ab/00000000000000007737074e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f862ab/00000000000000007737074e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f862ab/00000000000000007737074e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"canvas-script";
src:url("https://use.typekit.net/af/106b9d/0000000000000000774ba093/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/106b9d/0000000000000000774ba093/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/106b9d/0000000000000000774ba093/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

/* abhaya-libre-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Abhaya Libre';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/abhaya-libre-v14-latin/abhaya-libre-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('./fonts/abhaya-libre-v14-latin/abhaya-libre-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/abhaya-libre-v14-latin/abhaya-libre-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/abhaya-libre-v14-latin/abhaya-libre-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/abhaya-libre-v14-latin/abhaya-libre-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/abhaya-libre-v14-latin/abhaya-libre-v14-latin-regular.svg#AbhayaLibre') format('svg'); /* Legacy iOS */
  }

.tk-basic-sans { font-family: "basic-sans",sans-serif; }
.tk-adlery-pro { font-family: "adlery-pro",sans-serif; }
.tk-canvas-script { font-family: "canvas-script",sans-serif; }

.abhaya-libre-regular { font-family: "Abhaya Libre",sans-serif; }