.App {
  text-align: center;
  font-family: "Abhaya Libre", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-family: "adlery-pro",sans-serif;
  font-size: 4em;
  padding-top: 30px;
}

h2 {
  font-family: "canvas-script",sans-serif;
}

h3 {
  font-family: "canvas-script",sans-serif;
}

h4 {
  font-family: "canvas-script",sans-serif;
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.timeline-sep {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  transform: translate(-50%, -50%);
  background-color: rgb(51, 51, 51);
  border-radius: 100rem;
  padding: 0;
}

@media (min-width: 767px) {
  .timeline {
    position: relative;
  }

  .timeline::before {
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    top: 0px;
    bottom: 0px;
    transform: translate(-50%, 0%);
    border-left: 1px dashed rgb(51, 51, 51);
  }

  .timeline::after {
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    height: calc(50% - 0.6rem);
    bottom: 0px;
    transform: translate(-50%, 0%);
    border-left: 1px dashed rgb(51, 51, 51);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: var(--invitations);
}

.title {
  width: 25%;
  height: auto;
  margin-top: 20px;
}

.footer {
  width: 10%;
  height: auto;
}

.home {
  margin: auto;
  width: 50%;
}

.story{
  margin: auto;
  width: 50%;
}

.timeline-element{
  width: 22%;
  height: fit-content;
  margin-top: 60px;
}

.offset-timeline-element{
  width: 22%;
}

.timeline-element-full {
  width: 40%;
  margin: auto;
  text-align: center;
}

.photogallery{
  margin: auto;
  width: 1000px;
}

.photogallery-image-big {
  background-color: var(--invitations);
  width: 600px;
  height: auto;
  object-fit: contain;
}

.photogallery-image {
  background-color: var(--invitations);
  width: 300px;
  height: 250px;
  object-fit: contain;
  margin: 5px;
}

.photogallery-image-even {
  background-color: var(--invitations);
  width: 300px;
  height: 250px;
  object-fit: contain;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.wedding-party {
  margin: auto;
  width: 50%;
}

.wedding-party-parent {
  width: 24%;
}

.wedding-party-element {
  width: 50%;
  margin: auto;
}

.q-a {
  margin: auto;
  width: 40%;
}

.travel {
  margin: auto;
  width: 40%;
}

.registry {
  margin: auto;
  width: 40%;
}

.things-to-do {
  margin: auto;
  width: 50%;
}

.random-pic {
  width: 40%;
  height: auto;
  padding: 10px;
  object-fit: contain;
  border-radius: 16px;
  margin:auto;
}

.smaller-pic {
  width: 20%;
  height: auto;
  padding: 10px;
  object-fit: contain;
  border-radius: 16px;
  margin:auto;
}

@media (max-width: 1200px) {
  .things-to-do {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .title {
    width: 80%;
    margin-top: 75px;
  }
  .footer {
    width: 30%;
  }
  body {
      margin-top: 50px;
  }
  .navbar-default {
      position: fixed;
      z-index: 1030;
      right: 0;
      left: 0;
      border-radius: 0;
      top: 0;
      border-width: 0 0 1px;
      background-color: var(--invitations);
      border-bottom: 1px solid rgb(0,0,0,0.2);
  }
  .home {
    width: 90%;
  }
  .story {
    width: 90%;
  }
  .timeline-element{
    width: 90%;
    margin: auto;
  }
  .offset-timeline-element{
    width: 90%;
  }
  .timeline-element-full {
    width: 90%;
  }
  .photogallery{
    width: 90%;
  }
  .photogallery-image-big {
    width: 100%;
    height: auto;
  }
  .photogallery-image {
    width: 25%;
    height: auto;
  }
  .photogallery-image-even {
    width: 40%;
    height: auto;
  }
  .wedding-party {
    width: 90%;
  }
  .wedding-party-parent {
    width: 90%;
  }
  .wedding-party-element {
    width: 95%;
  }
  .q-a {
    width: 90%;
  }
  .travel {
    width: 90%;
  }
  .things-to-do {
    width: 90%;
  }
  .random-pic {
    width: 90%;
  }
  .registry {
    width: 90%;
  }
}

@media (max-width: 575px) {
  .title {
    margin-top: 25px;
  }
}
