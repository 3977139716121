:root {
  --bridalblush: #eee2dd;
  --petalpink: #F2E2E0;
  --peachblush: #E6CDC5;
  --silverpeony: #e7cfc7;
  --pearlblush: #f4cec5;
  --blushingrose: #f7c0bf;
  --creolepink: #f7d5cc;
  --peachblush2: #e4ccc6;
  --desertsage: #a7ae9e;
  --celadon: #b8ccba;
  --birdsegggreen: #aaccb8;
  --invitations: #f7f2f2;
}

body {
  margin: 0;
  font-family: "basic-sans", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--invitations);
}

code {
  font-family: "basic-sans", sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


